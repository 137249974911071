import React, { useEffect, useState } from 'react';
import '../styles/contact.css';
import { ONESOUNDCRACKERS, chakkar, flowerPots, bomb, pencil, twinklingstar, rocket, fancy,shower,fancynovelist,fountain,fancy2,shot,matches,sparkles,chorsa,newvarieties} from './ProductData';
import ProductTableComponents from './ProductTableComponents';
import { Link } from 'react-router-dom';
import crackers from '../assets/crackers.png';
import bombimg from '../assets/bomb.png';

function QuickPurchase() {

    const [totalAmount, setTotalAmount] = useState([])
    const [totalProductsAmount, setTotalProductsAmount] = useState(0)
    const [totalProducts, setTotalProducts] = useState(0)
    useEffect(() => {
        const oldData = JSON.parse(localStorage.getItem('productData')) || [];
        setTotalProducts(oldData.length)
        let productTotalPrice = 0;
        oldData && oldData.map((nd) => {
            productTotalPrice += nd.quantity * nd.price;
        })
        setTotalProductsAmount(productTotalPrice)

        setTotalAmount(oldData)
    }, [])

    function getQuantity(item, value) {

        const existingData = JSON.parse(localStorage.getItem('productData')) || [];

        if (value > 0 && parseInt(value)) {
            // Retrieve existing data from localStorage

            // Check if the product details already exist in the data
            const existingProductIndex = existingData.findIndex(ed => ed.code === item.code);

            if (existingProductIndex !== -1) {
                // Product details exist, update the quantity
                existingData[existingProductIndex].quantity = parseInt(value);
            } else {
                // Product details do not exist, insert a new object
                const newProduct = {
                    ...item,
                    quantity: parseInt(value),
                    // Add other product details here
                };
                existingData.push(newProduct);
            }
            // Save the updated data back to localStorage
            localStorage.setItem('productData', JSON.stringify(existingData));


            const newData = JSON.parse(localStorage.getItem('productData')) || [];

            let productTotalPrice = 0;
            newData && newData.map((nd) => {
                productTotalPrice += nd.quantity * nd.price;
            })
            setTotalProductsAmount(productTotalPrice)
            setTotalProducts(newData.length)
            setTotalAmount(newData)
        } else {
            const updatedData = existingData.filter(ed => ed.code !== item.code);

            localStorage.setItem('productData', JSON.stringify(updatedData));
            const newData = JSON.parse(localStorage.getItem('productData')) || [];
            let productTotalPrice = 0;
            newData && newData.map((nd) => {
                productTotalPrice += nd.quantity * nd.price;
            })
            setTotalProducts(newData.length)
            setTotalProductsAmount(productTotalPrice)
            setTotalAmount(updatedData)
        }

    }
    const [totAmount, setTotAmount] = useState(0)
    const [oldQuantoty, setOldQuantity] = useState(0)


    return (
        <div>
            <div className='contact bg bg-fixed bg-overlay-black-70'>

                <div className='contact-inner mt-5'>
                    <div className='contact-title mt-5'>
                        <h3>Quick Purchase</h3>
                    </div>
                    <div className='contactlist mt-4'>
                        <ul>
                            <li><i class="fa-solid fa-house"></i><span>Home</span></li>
                            <li><i class="fa-solid fa-angles-right"></i></li>
                            <li>Pages</li>
                            <li><i class="fa-solid fa-angles-left"></i></li>
                            <li>Quick Purchase</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='px-2 px-sm-3 px-md-4 px-lg-5 pb-5 pt-5 quick_purchase'>
                <div className='d-flex justify-content-center pb-3' style={{fontSize : '50px'}}>
           < img src={bombimg} height={40} width={40} />
                                    <span className='font-bold'>80% Discount Offer </span> 
                                     <img src={crackers} height={40} width={40} /> </div>
                <div className='px-3 common_bgred row fw-bold product-sticky'>
                    <div className='col-12 col-sm-12 col-md-6 py-2 text-dark text-center text-sm-center text-md-end pe-0 pe-sm-0 pe-md-5'>Total Products : <span>{totalProducts && totalProducts}</span></div>
                    <div className='col-12 col-sm-12 col-md-6 py-2 text-dark text-center text-sm-center text-md-start'>Overall Total  : <span>{totalProductsAmount && totalProductsAmount} INR</span></div>
                </div>
                <div className='mt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>ONE SOUND CRACKERS</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>

                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    {/* <th scope="col">Content</th> */}
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ONESOUNDCRACKERS && ONESOUNDCRACKERS.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                        {/* <div>
                            <div className='row'>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </div>
                        </div> */}
                    </div>
                    <div className='mt-3'>
                        <h3 className='fw-bold lead secondary_color'><u>CHAKKAR</u></h3>
                        <div className='pt-3 table_wrap'>
                            <table className="table table-bordered">
                                <thead>
                                    <tr className=' text-center'>

                                        <th scope="col">Code</th>
                                        <th scope="col">Product</th>
                                        {/* <th scope="col">Content</th> */}
                                        <th scope="col">Actual Rate (Rs.)</th>
                                        <th scope="col">Price (Rs.)</th>
                                        <th scope="col" style={{ width: '10%' }}>Qty</th>
                                        <th scope="col">Total (Rs.)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {chakkar && chakkar.map((item) => (
                                        <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                    ))}
                                </tbody>
                            </table>
                            {/* <div>
                            <div className='row'>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </div>
                        </div> */}
                        </div>


                    </div>
                    <div className='mt-3'>
                        <h3 className='fw-bold lead secondary_color'><u>Flower Pots</u></h3>
                        <div className='pt-3 table_wrap'>
                            <table className="table table-bordered">
                                <thead>
                                    <tr className=' text-center'>

                                        <th scope="col">Code</th>
                                        <th scope="col">Product</th>
                                        {/* <th scope="col">Content</th> */}
                                        <th scope="col">Actual Rate (Rs.)</th>
                                        <th scope="col">Price (Rs.)</th>
                                        <th scope="col" style={{ width: '10%' }}>Qty</th>
                                        <th scope="col">Total (Rs.)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {flowerPots && flowerPots.map((item) => (
                                        <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                    ))}
                                </tbody>
                            </table>
                            {/* <div>
                            <div className='row'>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </div>
                        </div> */}
                        </div>


                    </div>
                    <div className='mt-3'>
                        <h3 className='fw-bold lead secondary_color'><u>Bomb</u></h3>
                        <div className='pt-3 table_wrap'>
                            <table className="table table-bordered">
                                <thead>
                                    <tr className=' text-center'>

                                        <th scope="col">Code</th>
                                        <th scope="col">Product</th>
                                        {/* <th scope="col">Content</th> */}
                                        <th scope="col">Actual Rate (Rs.)</th>
                                        <th scope="col">Price (Rs.)</th>
                                        <th scope="col" style={{ width: '10%' }}>Qty</th>
                                        <th scope="col">Total (Rs.)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bomb && bomb.map((item) => (
                                        <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                    ))}
                                </tbody>
                            </table>
                            {/* <div>
                            <div className='row'>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </div>
                        </div> */}
                        </div>
                        <div className='mt-3'>
                            <h3 className='fw-bold lead secondary_color'><u>pencil</u></h3>
                            <div className='pt-3 table_wrap'>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className=' text-center'>

                                            <th scope="col">Code</th>
                                            <th scope="col">Product</th>
                                            {/* <th scope="col">Content</th> */}
                                            <th scope="col">Actual Rate (Rs.)</th>
                                            <th scope="col">Price (Rs.)</th>
                                            <th scope="col" style={{ width: '10%' }}>Qty</th>
                                            <th scope="col">Total (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pencil && pencil.map((item) => (
                                            <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                        ))}
                                    </tbody>
                                </table>
                                {/* <div>
                            <div className='row'>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </div>
                        </div> */}
                            </div>



                        </div>
                        <div className='mt-3'>
                            <h3 className='fw-bold lead secondary_color'><u>Twinkling Star</u></h3>
                            <div className='pt-3 table_wrap'>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className=' text-center'>

                                            <th scope="col">Code</th>
                                            <th scope="col">Product</th>
                                            {/* <th scope="col">Content</th> */}
                                            <th scope="col">Actual Rate (Rs.)</th>
                                            <th scope="col">Price (Rs.)</th>
                                            <th scope="col" style={{ width: '10%' }}>Qty</th>
                                            <th scope="col">Total (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {twinklingstar && twinklingstar.map((item) => (
                                            <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                        ))}
                                    </tbody>
                                </table>
                                {/* <div>
                            <div className='row'>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </div>
                        </div> */}
                            </div>
                        </div>
                        <div className='mt-3'>
                            <h3 className='fw-bold lead secondary_color'><u>ROCKET </u></h3>
                            <div className='pt-3 table_wrap'>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className=' text-center'>

                                            <th scope="col">Code</th>
                                            <th scope="col">Product</th>
                                            {/* <th scope="col">Content</th> */}
                                            <th scope="col">Actual Rate (Rs.)</th>
                                            <th scope="col">Price (Rs.)</th>
                                            <th scope="col" style={{ width: '10%' }}>Qty</th>
                                            <th scope="col">Total (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rocket && rocket.map((item) => (
                                            <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                        ))}
                                    </tbody>
                                </table>
                                {/* <div>
                            <div className='row'>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </div>
                        </div> */}
                            </div>
                        </div>
                        <div className='mt-3'>
                            <h3 className='fw-bold lead secondary_color'><u>Fancy </u></h3>
                            <div className='pt-3 table_wrap'>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className=' text-center'>

                                            <th scope="col">Code</th>
                                            <th scope="col">Product</th>
                                            {/* <th scope="col">Content</th> */}
                                            <th scope="col">Actual Rate (Rs.)</th>
                                            <th scope="col">Price (Rs.)</th>
                                            <th scope="col" style={{ width: '10%' }}>Qty</th>
                                            <th scope="col">Total (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fancy && fancy.map((item) => (
                                            <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                        ))}
                                    </tbody>
                                </table>
                                {/* <div>
                            <div className='row'>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </div>
                        </div> */}
                            </div>
                        </div>
                        <div className='mt-3'>
                            <h3 className='fw-bold lead secondary_color'><u>SHOWER  </u></h3>
                            <div className='pt-3 table_wrap'>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className=' text-center'>

                                            <th scope="col">Code</th>
                                            <th scope="col">Product</th>
                                            {/* <th scope="col">Content</th> */}
                                            <th scope="col">Actual Rate (Rs.)</th>
                                            <th scope="col">Price (Rs.)</th>
                                            <th scope="col" style={{ width: '10%' }}>Qty</th>
                                            <th scope="col">Total (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {shower && shower.map((item) => (
                                            <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                        ))}
                                    </tbody>
                                </table>
                                {/* <div>
                            <div className='row'>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </div>
                         </div> */}
                            </div>
                        </div>
                        <div className='mt-3'>
                            <h3 className='fw-bold lead secondary_color'><u>FANCY NOVELTIES   </u></h3>
                            <div className='pt-3 table_wrap'>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className=' text-center'>

                                            <th scope="col">Code</th>
                                            <th scope="col">Product</th>
                                            {/* <th scope="col">Content</th> */}
                                            <th scope="col">Actual Rate (Rs.)</th>
                                            <th scope="col">Price (Rs.)</th>
                                            <th scope="col" style={{ width: '10%' }}>Qty</th>
                                            <th scope="col">Total (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fancynovelist && fancynovelist.map((item) => (
                                            <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                        ))}
                                    </tbody>
                                </table>
                                {/* <div>
                            <div className='row'>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </div>
                         </div> */}
                            </div>
                        </div>
                        <div className='mt-3'>
                            <h3 className='fw-bold lead secondary_color'><u>Fountain   </u></h3>
                            <div className='pt-3 table_wrap'>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className=' text-center'>

                                            <th scope="col">Code</th>
                                            <th scope="col">Product</th>
                                            {/* <th scope="col">Content</th> */}
                                            <th scope="col">Actual Rate (Rs.)</th>
                                            <th scope="col">Price (Rs.)</th>
                                            <th scope="col" style={{ width: '10%' }}>Qty</th>
                                            <th scope="col">Total (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fountain && fountain.map((item) => (
                                            <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                        ))}
                                    </tbody>
                                </table>
                                {/* <div>
                            <div className='row'>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </div>
                         </div> */}
                            </div>
                        </div>
                        <div className='mt-3'>
                            <h3 className='fw-bold lead secondary_color'><u>Fancy   </u></h3>
                            <div className='pt-3 table_wrap'>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className=' text-center'>

                                            <th scope="col">Code</th>
                                            <th scope="col">Product</th>
                                            {/* <th scope="col">Content</th> */}
                                            <th scope="col">Actual Rate (Rs.)</th>
                                            <th scope="col">Price (Rs.)</th>
                                            <th scope="col" style={{ width: '10%' }}>Qty</th>
                                            <th scope="col">Total (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fancy2 && fancy2.map((item) => (
                                            <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                        ))}
                                    </tbody>
                                </table>
                                {/* <div>
                            <div className='row'>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </div>
                         </div> */}
                            </div>
                        </div>
                        <div className='mt-3'>
                            <h3 className='fw-bold lead secondary_color'><u>SHOT    </u></h3>
                            <div className='pt-3 table_wrap'>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className=' text-center'>

                                            <th scope="col">Code</th>
                                            <th scope="col">Product</th>
                                            {/* <th scope="col">Content</th> */}
                                            <th scope="col">Actual Rate (Rs.)</th>
                                            <th scope="col">Price (Rs.)</th>
                                            <th scope="col" style={{ width: '10%' }}>Qty</th>
                                            <th scope="col">Total (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {shot && shot.map((item) => (
                                            <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                        ))}
                                    </tbody>
                                </table>
                                {/* <div>
                            <div className='row'>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </div>
                         </div> */}
                            </div>
                        </div>
                        <div className='mt-3'>
                            <h3 className='fw-bold lead secondary_color'><u>Matches    </u></h3>
                            <div className='pt-3 table_wrap'>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className=' text-center'>

                                            <th scope="col">Code</th>
                                            <th scope="col">Product</th>
                                            {/* <th scope="col">Content</th> */}
                                            <th scope="col">Actual Rate (Rs.)</th>
                                            <th scope="col">Price (Rs.)</th>
                                            <th scope="col" style={{ width: '10%' }}>Qty</th>
                                            <th scope="col">Total (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {matches && matches.map((item) => (
                                            <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                        ))}
                                    </tbody>
                                </table>
                                {/* <div>
                            <div className='row'>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </div>
                         </div> */}
                            </div>
                        </div>
                        <div className='mt-3'>
                            <h3 className='fw-bold lead secondary_color'><u>SPARKLERS     </u></h3>
                            <div className='pt-3 table_wrap'>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className=' text-center'>

                                            <th scope="col">Code</th>
                                            <th scope="col">Product</th>
                                            {/* <th scope="col">Content</th> */}
                                            <th scope="col">Actual Rate (Rs.)</th>
                                            <th scope="col">Price (Rs.)</th>
                                            <th scope="col" style={{ width: '10%' }}>Qty</th>
                                            <th scope="col">Total (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sparkles && sparkles.map((item) => (
                                            <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                        ))}
                                    </tbody>
                                </table>
                                {/* <div>
                            <div className='row'>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </div>
                         </div> */}
                            </div>
                        </div>
                        <div className='mt-3'>
                            <h3 className='fw-bold lead secondary_color'><u>CHORSA    </u></h3>
                            <div className='pt-3 table_wrap'>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className=' text-center'>

                                            <th scope="col">Code</th>
                                            <th scope="col">Product</th>
                                            {/* <th scope="col">Content</th> */}
                                            <th scope="col">Actual Rate (Rs.)</th>
                                            <th scope="col">Price (Rs.)</th>
                                            <th scope="col" style={{ width: '10%' }}>Qty</th>
                                            <th scope="col">Total (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {chorsa && chorsa.map((item) => (
                                            <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                        ))}
                                    </tbody>
                                </table>
                                {/* <div>
                            <div className='row'>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </div>
                         </div> */}
                            </div>
                        </div>
                        <div className='mt-3'>
                            <h3 className='fw-bold lead secondary_color'><u>2024 NEW VARIETIES    </u></h3>
                            <div className='pt-3 table_wrap'>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className=' text-center'>

                                            <th scope="col">Code</th>
                                            <th scope="col">Product</th>
                                            {/* <th scope="col">Content</th> */}
                                            <th scope="col">Actual Rate (Rs.)</th>
                                            <th scope="col">Price (Rs.)</th>
                                            <th scope="col" style={{ width: '10%' }}>Qty</th>
                                            <th scope="col">Total (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {newvarieties && newvarieties.map((item) => (
                                            <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                        ))}
                                    </tbody>
                                </table>
                                {/* <div>
                            <div className='row'>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </div>
                         </div> */}
                            </div>
                        </div>
                    </div>



                </div>







            </div>

            <div className='checkout_parent'>
                <Link to='/checkout' className=' text-white'> <i className="fas fa-shopping-cart"></i></Link>
                <span className='checkout_product'>{totalProducts && totalProducts}</span>
            </div>
        </div>
    )
}

export default QuickPurchase