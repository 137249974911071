import React from 'react';
import look from '../assets/look.png';
import '../styles/About.css'

function About() {
    return (
        <div>
            <div className='price bg bg-fixed bg-overlay-black-70'>

                <div className='price-inner mt-5'>
                    <div className='price-title mt-5'>
                        <h3>About Us</h3>
                    </div>
                    <div className='pricelist mt-4'>
                        <ul>
                            <li><i class="fa-solid fa-house"></i><span>Home</span></li>
                            <li><i class="fa-solid fa-angles-right"></i></li>
                            <li>Pages</li>
                            <li><i class="fa-solid fa-angles-left"></i></li>
                            <li>Price List</li>
                        </ul>
                    </div>

                </div>
            </div>
            <section className='mt-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-12 look'>
                            <img src={look} />
                        </div>
                        <div className='col-lg-6 col-12 about'>
                            <div className='about-title'>
                                <h1>About Us</h1>
                            </div><br></br>
                            <p>Maharaj Fireworks is one of the leading crackers dealer in Sivakasi. Since 2010 we have the good relationship with our valuable customers. Here you can get all kinds of crackers with affordable prices. Around 11 years our customers trusted us. We supply the crackers all over the Southern India. Our Customer support will be avialble 24 x 7.</p>

                            <h3>WE OFFER THE BEST QUALITY PRODUCTS AT BEST PRICE.</h3>
                            <p>One can buy crackers from us round the year.Buying quality crackers during all season we came up with the solution to buy crackers. The following are the advantage you can enjoy buying.</p><br></br>


                            <ul className='list-edit mt-20'>
                                <li className='list-edit'>You don't need to stand in long queue to buy. Instead you can shop from home at a click of a button</li>
                                <li className='list-edit'>Selection procedure gets simpler</li>
                                <li className='list-edit'>All our varieties with detailed description of the products are available.</li>
                                <li className='list-edit'>We request you to include us as a part of your grand celebration!</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About