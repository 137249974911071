import React from 'react';
import safe1 from '../assets/safe1.png';
import safe2 from '../assets/safe2.png';
import '../styles/safety.css'

function Safety() {
    return (
        <div>
            <div className='price bg bg-fixed bg-overlay-black-70'>

                <div className='price-inner mt-5'>
                    <div className='price-title mt-5'>
                        <h3>Safety Tips</h3>
                    </div>
                    <div className='pricelist mt-4'>
                        <ul>
                            <li><i class="fa-solid fa-house"></i><span>Home</span></li>
                            <li><i class="fa-solid fa-angles-right"></i></li>
                            <li>Pages</li>
                            <li><i class="fa-solid fa-angles-left"></i></li>
                            <li>Price List</li>
                        </ul>
                    </div>

                </div>
            </div>
            <section className='mt-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='row'>
                                <div className='col-lg-6 col-12'>
                                    <img src={safe1} className='img-responsive' />
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <img src={safe2} className='img-responsive' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12 mt-3'>
                            <div className='row'>
                                <div className='col-lg-6 col-12'>
                                    <img src={safe2} className='img-responsive' />
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <img src={safe1} className='img-responsive' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='container mt-5'>
                <h5>You Can Ensure That This Diwali Is A 'Safe Diwali' With These Tips</h5>
                <div className='table table-scrollable mt-5'>
                    <table className='table table-bordered'>
                        <tbody>
                            <tr>
                                <td className='left'>Clothes and Shoes</td>
                                <td><p>To avoid your clothes getting on fire, it is strictly advised to wear cotton clothes while bursting crackers. You mustn’t wear synthetic clothes since they easily catch fire. You must also ensure that you wear covered shoes to prevent any feet injury.</p></td>
                            </tr>
                            <tr>
                                <td className='left'>Fire Extinguisher</td>
                                <td><p>Always keep a fire extinguisher in the close proximity of the place where you are bursting crackers. You can also keep buckets of water and sand handy. In case of a fire accident, immediately inform the fire brigade.</p></td>
                            </tr>
                            <tr>
                                <td className='left'>
                                    Use Crackers wisely
                                </td>
                                <td><p>Always purchase good quality crackers. Never burst crackers in any indoor place. Always find a proper outdoor setting to burst crackers. Light crackers at a distance from where people have gathered. Light one cracker at a time and after lighting it, keep a safe distance between yourself and the lighted cracker. Remember these safety tips while playing with crackers.</p></td>
                            </tr>
                            <tr>
                                <td className='left'>Be Sensitive</td>
                                <td><p>As you know that crackers are responsible for air and noise pollution, hence avoid bursting them near hospitals or crowded public places. Always remember that your celebration should not be a reason of discomfort to others.</p></td>
                            </tr>
                            <tr>
                                <td className='left'>Dispose of Crackers properly</td>
                                <td><p>Once the cracker has been completely burnt, remember to dispose of it safely. Put them in a bucket of water or extinguish them by putting sand around them.</p></td>
                            </tr>
                            <tr>
                                <td className='left'>Supervise and Instruct Children</td>
                                <td><p>A very important safety tip for Diwali is to ensure that children play with crackers under adult supervision. You must instruct your kids about the usage of firecrackers and the proper ways of disposing them Never should you allow any minor to play with crackers alone.</p></td>
                            </tr>
                            <tr>
                                <td className='left'>Frist Aid Kit</td>
                                <td><p>Always keep a first aid kit handy. In case of minor burns or injuries, you can get immediate medical attention.</p></td>
                            </tr>
                            <tr>
                                <td className='left'>Candles and Diyas</td>
                                <td><p>It is during this time of the year that we decorate and our houses with candles and diyas. Do ensure that the candles are away from curtains or other inflammable items at home.</p></td>
                            </tr>
                            <tr>
                                <td className='left'>Pet Safety</td>
                                <td><p>Pets might find a difficult time with so much noise around. As a safety tip it is recommended that you keep your pets away from crackers.</p></td>
                            </tr>
                            <tr>
                                <td className='left'>Spread Awareness</td>
                                <td><p>Spread the safety tips among all the people around you, so that you can ensure a safe Diwali for all of them.</p></td>
                            </tr>
                        </tbody>
                    </table>
                    <p className='center-text'>Celebrate this festival of lights with great pomp and joy. Stay Safe and Spread Happiness.</p>
                </div>
            </section>
        </div>
    )
}

export default Safety