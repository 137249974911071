import React from 'react';
import '../styles/Terms.css'

function Terms() {
    return (
        <div>
            <div className='price bg bg-fixed bg-overlay-black-70'>

                <div className='price-inner mt-5'>
                    <div className='price-title mt-5'>
                        <h3>Terms & Conditions</h3>
                    </div>
                    <div className='pricelist mt-4'>
                        <ul>
                            <li><i class="fa-solid fa-house"></i><span>Home</span></li>
                            <li><i class="fa-solid fa-angles-right"></i></li>
                            <li>Pages</li>
                            <li><i class="fa-solid fa-angles-left"></i></li>
                            <li>Price List</li>
                        </ul>
                    </div>

                </div>
            </div>
            <section>
                <div className='mt-5 container'>
                    <p>This website is owned and operated by Maharajpyropark and is only for your personal use and not for any commercial purpose. Your use of this website is subjected to the following terms and conditions. By checking out, you are agreeing to these terms and conditions of Maharajpyropark. If you do not agree to the terms and conditions and privacy policy posted on the website, please do not use the website or the services offered by this website. The Maharajpyropark holds all the rights to change the terms and conditions at
                        any time without any prior notice. We request our users to check and be aware of the changes in the privacy policy and terms and conditions.</p><br></br>
                        <h1><u>Our Policies</u></h1>
                    <ul className='list-edit mt-20'>
                        <li className='list-edit'>All Above rate are F.O.R sivakasi</li>
                        <li className='list-edit'>Payment 100% in advance</li>
                        <li className='list-edit'>Lorry Freight, Charity, Insurance, Parking Charge Extra </li>
                        <li className='list-edit'>Applicable GST Tax Extra </li>
                    </ul>
                    <h1><u>Order Placement</u></h1>    
                    <ul className='list-edit mt-20'>
                        <li className='list-edit'>
                            We accept orders all over india.Regular Services available for Kerela, Karnataka, Andhra Pradesh, Telangana & Tamilnadu.</li>
                        <li className='list-edit'>Minimum order value for TamilNadu is INR 3000/- and for other states, the minimum order value will be INR 5000/-</li>
                        <li className='list-edit'>Maharajpyropark do not ship crackers to the cities where crackers are banned</li>
                        <li className='list-edit'>
                            You can use the website to check desired products in cart and drop your enquiry.</li>
                        <li className='list-edit'>Once we receive the enquiry, our customer care executive will call you to confirm and provide you with the delivery details within 24 hours.</li>
                        <li className='list-edit'>We accept Diwali booking upto 15/10/2021 (North India), 24/10/2021 (South India) & 28/10/2021 (Tamil Nadu & Bengaluru, Heyderabad, Nellore & Vijayawada)</li>
                    </ul>
                   
                    <h1><u>Payment Policies</u></h1>
                    <ul className='list-edit mt-20'>
                        <li className='list-edit'>Products will be dispatched only after the entire payment is made.</li>
                        <li className='list-edit'>Prices may change subject to the right owned by the Q Traders</li>
                    </ul>

                    <h1><u>Shipping & Delivery</u></h1>
                    <ul className='list-edit mt-20'>
                        <li className='list-edit'>Delivery is done by third party agencies.</li>
                        <li className='list-edit'>On every order, delivery charges should be barred by the customer.</li>
                        <li className='list-edit'>We do not take responsibility for any damages/product missing incurred by transport. However, we will try and deliver the products without any damage by our continuous and vigilant monitoring.</li>
                        <li className='list-edit'>Goods will be packed only in special cartons.</li>
                    </ul>
                    <h1><u>Disclaimer</u></h1>
                    <ul className='list-edit mt-20'>
                        <li className='list-edit'>Maharajpyropark.in ' is just a reference to check the availability of our products and price list.</li>
                        <li className='list-edit'>Add your wish list products in the 'Quick Purchase' and submit the enquiry. We will be contacting you in another 24 hrs.</li>
                        <li className='list-edit'>The product images on the website are for representative purpose only. The delivered product may vary in appearance and packaging.</li>
                    </ul>
                </div>
            </section>
        </div>
    )
}

export default Terms