import React from 'react';
import '../styles/footer.css'


function Footer() {
    return (
        <div>
            <div className='footer'>
                <div className='container foot'>
                    <div className='row foot-top'>
                        <div className='col-sm-12'>
                            <div className='top-info'>
                                <div className='address position-relative ps-5 ps-md-5 ps-lg-5'>
                                    <i class="fa-solid fa-house-circle-exclamation"></i>
                                    <div>
                                        <h3>Location:</h3>
                                        <span>S.No: 172 /1, D.NO : 1/372 A,<br></br>
                                        Keelathiruthangal,<br></br>
                                         Virudhunagar road, Sivakasi - 626 123.
                                        </span></div>
                                </div>
                                <div className='address1 position-relative ps-5 ps-md-5 ps-lg-5'>
                                    <i class="fa-solid fa-phone-volume"></i>
                                    <div>
                                        <h3>Phone:</h3>
                                        <span><a href='tel:+919843529357' className=' text-dark'> (+91) 9843529357</a>
                                        </span></div>
                                </div>
                                <div className='address2 position-relative ps-5 ps-md-5 ps-lg-5'>
                                    <i class="fa-solid fa-house-circle-exclamation"></i>
                                    <div><h3>Mail:</h3>
                                        <span><a href='maharajpyropark@gmail.com' className=' text-dark'>maharajpyropark@gmail.com</a>
                                        </span> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-5 last'>
                        <div className='col-lg-5 col-md-5 col-sm-6-mb-30'>
                            <div className='foot-about'>
                                <div className='foot-title'>
                                    <h4 className='primary_color'>About Us</h4>
                                </div>
                                <p className='white mt-3 mt-sm-4 mt-md-5'>Maharaj Fireworks is one of the leading crackers dealer in Sivakasi. Since 2010 we have the good relationship with our valuable customers. Here you can get all kinds of crackers with affordable prices. Around 11 years our customers trusted us. We supply the crackers all over the Southern India. Our Customer support will be avialble 24 x 7.</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mb-30'>
                            <div className='foot-link'>
                                <div className='foot-title'>
                                    <h4 className='primary_color'>Quick Links</h4>
                                </div>
                                <ul className='mt-3 mt-sm-4 mt-md-5 tic'>
                                    <li><a href='/aboutus'>About Us</a></li>
                                    <li><a href='/terms&conditions'>Terms & Conditions</a></li>
                                    <li><a href='/pricelist'>Price List</a></li>
                                    <li><a href='/quick-purchase'>Quick Purchase</a></li>
                                    <li><a href='contactus'>Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-4col-md-4 col-sm-6 mb-30'>
                            <div className='foot-address'>
                                <div className='foot-title'>
                                    <h3 className='primary_color'>Get In Touch</h3>
                                </div>
                                <ul className='mt-5 foot-icon ps-0'>
                                    <li className='ps-4'><i class="fa-solid fa-location-dot"></i><span>S.No: 172 / 1, D.NO : 1/372 A,<br></br>
                                    Keelathiruthangal,<br></br>
                                     Virudhunagar road, Sivakasi - 626 123.</span>
                                    </li>
                                    <li className='ps-4'><i class="fa-solid fa-location-dot"></i><span><a href='tel:+919843529357' className=' text-white'>(+91) 9843529357</a></span> </li>
                                    <li className='ps-4'><i class="fa-solid fa-envelope"></i><span><a href='maharajpyropark@gmail.com' className=' text-white'>maharajpyropark@gmail.com</a></span></li>

                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='footer-widget'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6 text-center text-lg-start'>
                                <p className='white'>	&copy;2023.All Rights Reserved -Maharaj Fireworks</p>
                            </div>
                            <div className='col-lg-6 text-center text-lg-start'>
                                <p className='white'>Designed by <span className='spa'>Netbix Technologies</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
       
    )
}

export default Footer