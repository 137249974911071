import React from 'react';
import '../styles/style.css';
import bomb from '../assets/bomb.png';
import crackers from '../assets/crackers.png';
import logo from '../assets/maharaj-logo.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';

function Nav() {
    const [clicked, setClicked] = useState(false);

    const handleclick = () => {
        setClicked(!clicked
        );
    }
    return (
        <div className='whole'>
            <div className='top-navbar'>
                <div className='first-top-nav d-flex'>
                    <div className='d-flex gap-2'><span><i class="fa-regular fa-envelope"></i></span><span><a href='mailto:maharajpyropark@gmail.com' className=' text-white'>maharajpyropark@gmail.com</a></span></div>
                    <div className='d-flex gap-2'><span><i class="fa-solid fa-clock"></i></span><span>All Days : 7.00 A.M - 11.00 P.M</span></div>
                </div>
                <div className='second-top-nav'>
                    <span><i class="fa-solid fa-phone"></i></span>
                    <span><a href='tel:+919843529357' className=' text-white'>(+91 ) 9843529357</a></span>
        
                </div>
            </div>
            <div className='bottom-navbar'>
                <div className='logo'>
                   <a href='/'><img src={logo} height={100} width={180} /></a>
                </div>
                <div className='navbar-toggle'></div>
                <div>
                    <ul className={clicked ? "menu-links active" : "menu-links "}>
                        <li className='home'><Link to="/">HOME</Link></li>
                         <li className='hover'>PROFILE  <span><i class="fa-solid fa-caret-down"></i></span>
                            <div className='drop'>
                                <ul>
                                    <li><a href='/aboutus'>About us</a></li>
                                    <li><a href='terms&conditions'>Terms & Conditions</a></li>
                                    <li><a href='safety'>Safety Tips</a></li>
                                </ul>
                            </div>
                        </li>
                        <li><Link to='/pricelist'>PRICE LIST</Link></li>
                        <li><Link to='/quick-purchase'>QUICK PURCHASE</Link></li>
            
                        <li><Link to='/contactus'>CONTACT US</Link></li>
                        
                    </ul>

                </div>
                <div className='mobile' onClick={handleclick}>
                    <i class={clicked ? "fa-solid fa-xmark" : "fa-solid fa-bars"}></i>
                </div>

            </div>
            <div className='marque'>
                <marquee loop='infinite' scrollamount='12'>
                    <h3>
                        <img src={bomb} height={40} width={40} />80% Discount Offer
                        <img src={crackers} height={40} width={40} />
                        <span>|</span>
                        <img src={bomb} height={40} width={40} />Minimum Order For Tamilnadu Rs.2,500/- other States Rs.6,000
                        <img src={crackers} height={40} width={40} />

                        <img src={bomb} height={40} width={40} />80% Discount Offer
                        <img src={crackers} height={40} width={40} />
                        <span>|</span>
                        <img src={bomb} height={40} width={40} />Minimum Order For Tamilnadu Rs.2,500/- other States Rs.6,000
                        <img src={crackers} height={40} width={40} />
                        <span>|</span>
                        <img src={bomb} height={40} width={40} />80% Discount Offer
                        <img src={crackers} height={40} width={40} />
                        <span>|</span>
                        <img src={bomb} height={40} width={40} />Minimum Order For Tamilnadu Rs.2,500/- other States Rs.6,000
                        <img src={crackers} height={40} width={40} />
                        <span>|</span>
                        <img src={bomb} height={40} width={40} />80% Discount Offer
                        <img src={crackers} height={40} width={40} />
                        <span>|</span>
                        <img src={bomb} height={40} width={40} />Minimum Order For Tamilnadu Rs.2,500/- other States Rs.6,000
                        <img src={crackers} height={40} width={40} />
                        <span>|</span>
                    </h3>
                </marquee>
            </div>
            {/* <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img className="d-block w-100" src={slider1} alt="First slide" />
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100" src={slider2} alt="Second slide" />
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100" src={slider3} alt="Third slide" />
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div> */}


        </div>
    )
}

export default Nav