import React, { useEffect, useState } from 'react'
import CheckoutTableComponents from './CheckoutTableComponents';
import { Link, useNavigate } from 'react-router-dom';
import validator from 'validator';
import Swal from 'sweetalert2';
import axios from 'axios';

const Checkout = () => {
    const [checkOutDate, setCheckoutData] = useState([])
    const [buttonDisable, setButtonDisable] = useState(false);
    const [totalProductsAmount, setTotalProductsAmount] = useState(0)
    const [totalAmount, setTotalAmount] = useState([])
    const [packCharges, setPackCharges] = useState(0)
    const [paybleAmount, setPaybleAmount] = useState(0)
    const navigate = useNavigate();
    useEffect(() => {
        const oldData = JSON.parse(localStorage.getItem('productData')) || [];
        if (oldData.length > 0) {
            setCheckoutData(oldData)

            let productTotalPrice = 0;
            oldData && oldData.map((nd) => {
                productTotalPrice += nd.quantity * nd.price;
            })
            let productCharges = productTotalPrice / 100 * 3;
            setPackCharges(productCharges);
            setTotalProductsAmount(productTotalPrice)
            setPaybleAmount(parseInt(productCharges) + productTotalPrice)
            setTotalAmount(oldData)
        }
        // else{
        //     navigate('/quick-purchase')
        // }

    }, [checkOutDate])

    function getQuantity(item, value) {

        const existingData = JSON.parse(localStorage.getItem('productData')) || [];

        if (value > 0 && parseInt(value)) {
            // Retrieve existing data from localStorage

            // Check if the product details already exist in the data
            const existingProductIndex = existingData.findIndex(ed => ed.code === item.code);

            if (existingProductIndex !== -1) {
                // Product details exist, update the quantity
                existingData[existingProductIndex].quantity = parseInt(value);
            } else {
                // Product details do not exist, insert a new object
                const newProduct = {
                    ...item,
                    quantity: parseInt(value),
                    // Add other product details here
                };
                existingData.push(newProduct);
            }
            // Save the updated data back to localStorage
            localStorage.setItem('productData', JSON.stringify(existingData));


            const newData = JSON.parse(localStorage.getItem('productData')) || [];

            let productTotalPrice = 0;
            newData && newData.map((nd) => {
                productTotalPrice += nd.quantity * nd.price;
            })
            let productCharges = productTotalPrice / 100 * 3;
            setPackCharges(productCharges);
            setTotalProductsAmount(productTotalPrice)
            setPaybleAmount(parseInt(productCharges) + productTotalPrice)
            setTotalAmount(newData)
        } else {
            const updatedData = existingData.filter(ed => ed.code !== item.code);

            localStorage.setItem('productData', JSON.stringify(updatedData));
            const newData = JSON.parse(localStorage.getItem('productData')) || [];
            let productTotalPrice = 0;
            newData && newData.map((nd) => {
                productTotalPrice += nd.quantity * nd.price;
            })
            let productCharges = productTotalPrice / 100 * 3;
            setPackCharges(productCharges);
            setTotalProductsAmount(productTotalPrice)
            setPaybleAmount(parseInt(productCharges) + productTotalPrice)
            setTotalAmount(updatedData)
        }

    }

    function clearCart() {
        localStorage.removeItem('productData');
        setCheckoutData([])
        setTotalProductsAmount(0)
        setPackCharges(0)
        setPaybleAmount(0)
    }


    const [formData, setFormData] = useState({
        name: '',
        contactNumber: '',
        city: '',
        pincode: '',
        address: '',
    });


    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        setButtonDisable(true);
        e.preventDefault();
        const existingData = JSON.parse(localStorage.getItem('productData')) || [];

        if (existingData.length <= 0) {
            Swal.fire({
                icon: 'warning',
                title: 'Your Cart is Empty!',
                text: 'Please add products in your cart!!',
                showCancelButton: false,
                confirmButtonText: 'OK',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/quick-purchase')
                }
            });
            setButtonDisable(false);
            return false;
        }
        // Validate the form data here
        const validationErrors = {};

        if (validator.isEmpty(formData.name)) {
            validationErrors.name = 'Name is required';
        }

        if (!validator.isMobilePhone(formData.contactNumber, 'any', { strictMode: false })) {
            validationErrors.contactNumber = 'Contact Number is Required';
        }

        if (validator.isEmpty(formData.city)) {
            validationErrors.city = 'City is required';
        }

        if (!validator.isPostalCode(formData.pincode, 'any')) {
            validationErrors.pincode = 'Pincode is Required';
        }

        if (validator.isEmpty(formData.address)) {
            validationErrors.address = 'Address is required';
        }

        // If there are validation errors, update the state
        if (Object.keys(validationErrors).length > 0) {
            setButtonDisable(false);
            setErrors(validationErrors);

        } else {
            setButtonDisable(true);
            // Form data is valid, you can submit it or perform other actions
            setErrors({});
            console.log('Form data is valid:', formData);
            formData['products'] = existingData;
            formData['paybleAmount'] = paybleAmount;
            try {
                const response = await axios.post('https://maharajpyropark.in//back/index.php', formData);
                if (response.data.status == 200) {
                    setButtonDisable(false);
                    localStorage.removeItem('productData');
                    setCheckoutData([])
                    setTotalProductsAmount(0)
                    setPackCharges(0)
                    setPaybleAmount(0);
                    setFormData({
                        name: '',
                        contactNumber: '',
                        city: '',
                        pincode: '',
                        address: '',
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Your Order has been sent!!',
                        showCancelButton: false,
                        confirmButtonText: 'OK',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate('/quick-purchase')
                        }
                    });
                } else {
                    setButtonDisable(false);
                    Swal.fire({
                        icon: 'warning',
                        title: 'Something Wrong!',
                        text: 'Please try again Later!!',
                        showCancelButton: false,
                        confirmButtonText: 'OK',
                    })
                }
                // You can handle the response data as needed
            } catch (error) {
                setButtonDisable(false);
                Swal.fire({
                    icon: 'warning',
                    title: 'Something Wrong!',
                    text: 'Please try again Later!!',
                    showCancelButton: false,
                    confirmButtonText: 'OK',
                })
            }

            // fetch('http://localhost/back/index.php', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(formData),
            // })
            //     .then((response) => response.json())
            //     .then((data) => {
            //         console.log('Data sent successfully!', data);
            //         // You can handle the response data as needed
            //     })
            //     .catch((error) => {
            //         console.error('Error sending data:', error);
            //     });
        }
    };
    return (
        <div>
            <div className='contact bg bg-fixed bg-overlay-black-70'>

                <div className='contact-inner mt-5'>
                    <div className='contact-title mt-5'>
                        <h3>Checkout</h3>
                    </div>
                    <div className='contactlist mt-4'>
                        <ul>
                            <li><i class="fa-solid fa-house"></i><span>Home</span></li>
                            <li><i class="fa-solid fa-angles-right"></i></li>
                            <li>Pages</li>
                            <li><i class="fa-solid fa-angles-left"></i></li>
                            <li>Checkout</li>
                        </ul>
                    </div>
                </div>
            </div>


            <div className='px-5 pb-5 pt-5'>

                <div className=' checkout_table_wrap'>
                    <table className="table table-bordered">
                        <thead>
                            <tr className=' text-center'>
                                <th scope="col">Code</th>
                                <th scope="col">Product</th>
                                <th scope="col">Price (Rs.)</th>
                                <th scope="col" >Qty</th>
                                <th scope="col">Total (Rs.)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {checkOutDate && checkOutDate.map((item) => (
                                <CheckoutTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                            ))}
                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className=' fw-bold'>Sub Total</td>
                                <td className=' fw-bold h4'>₹ {totalProductsAmount && totalProductsAmount}</td>
                            </tr>
                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className=' fw-bold'>Packing charges (3%)</td>
                                <td className=' fw-bold h4'>(+) ₹ {parseInt(packCharges)}</td>
                            </tr>
                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className=' fw-bold'>Payable amount</td>
                                <td className=' fw-bold h4'>₹ {paybleAmount}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='mt-5'>
                    <h4 className='text-decoration-underline'>Delivery Address</h4>

                    <form className=' checkout_form' onSubmit={handleSubmit}>
                        <div className=' row pt-3 justify-content-between'>
                            <div className='col-12 col-sm-12 col-md-5 '>
                                <input type='text' placeholder='Customer Name *' className=' w-100' name="name"
                                    value={formData.name}
                                    onChange={handleChange} />
                                {errors.name && <p className='mt-2 py-2 px-2 text-white bg-danger rounded'>{errors.name}</p>}
                            </div>
                            <div className='col-12 col-sm-12 col-md-5 mt-3 mt-md-0'>
                                <input type='text' placeholder='City *' className=' w-100' name="city"
                                    value={formData.city}
                                    onChange={handleChange} />
                                {errors.city && <p className='mt-2 py-2 px-2 text-white bg-danger rounded'>{errors.city}</p>}
                            </div>
                        </div>
                        <div className=' row pt-3 justify-content-between'>
                            <div className='col-12 col-sm-12 col-md-5'>
                                <input type='text' placeholder='Contact No *' className='  w-100' name="contactNumber"
                                    value={formData.contactNumber}
                                    onChange={handleChange} />
                                {errors.contactNumber && <p className='mt-2 py-2 px-2 text-white bg-danger rounded'>{errors.contactNumber}</p>}

                            </div>
                            <div className='col-12 col-sm-12 col-md-5 mt-3 mt-md-0'>
                                <input type='text' placeholder='PIN Code *' className='w-100' name="pincode"
                                    value={formData.pincode}
                                    onChange={handleChange} />
                                {errors.pincode && <p className='mt-2 py-2 px-2 text-white bg-danger rounded'>{errors.pincode}</p>}

                            </div>
                        </div>
                        <div className=' row pt-3 justify-content-center justify-content-md-between align-items-start'>
                            <div className='col-12 col-sm-12 col-md-5'>
                                <textarea placeholder='Address *' className='  w-100' rows={8} name="address"
                                    value={formData.address}
                                    onChange={handleChange}></textarea>
                                {errors.address && <p className='mt-2 py-2 px-2 text-white bg-danger rounded'>{errors.address}</p>}

                            </div>
                            <div className=' row  col-12 col-sm-12 col-md-5 justify-content-between ps-0 mt-3 mt-md-0 '>
                                <span onClick={clearCart} className='cursor-pointer px-2 py-2 text-center fw-bold bg-danger text-white col-12 col-md-3 rounded d-flex align-items-center justify-content-center'>Clear All</span>
                                <Link to='/quick-purchase' className='mt-3 mt-md-0 cursor-pointer px-2 py-2 text-center fw-bold bg-primary text-white col-12 col-md-3 rounded d-flex align-items-center justify-content-center'>Back</Link>
                                <button type='submit' className={`${buttonDisable && 'pointer-events-none'} mt-3 mt-md-0  px-2 py-2 text-center fw-bold bg-success text-white col-12 col-md-3 rounded d-flex align-items-center justify-content-center`} >Order Now</button>
                            </div>
                        </div>
                    </form>
                </div>
                <h1 className='mt-5'><u>Our Policies</u></h1>
                <ul className='list-edit mt-20'>
                        <li className='list-edit'>All Above rate are F.O.R sivakasi</li>
                        <li className='list-edit'>Payment 100% in advance</li>
                        <li className='list-edit'>Lorry Freight, Charity, Insurance, Parking Charge Extra </li>
                        <li className='list-edit'>Applicable GST Tax Extra </li>
                    </ul>
            </div>
        </div>
    )
}

export default Checkout
