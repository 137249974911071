
export const ONESOUNDCRACKERS  = [
    { code: 1, productName: '2 3/4" Kuruvi Crackers ', actualRate: '₹ 75 ', price: 15 },
    { code: 2, productName: '3 1/2" Lakshmi Crackers ', actualRate: '₹ 120', price: 24 },
    { code: 3, productName: '4" Lakshmi special Crackers', actualRate: '₹ 150', price: 30 },
    { code: 4, productName: '4" Lakshmi Deluxe Crackers ', actualRate: '₹ 195', price: 39 },
    { code: 5, productName: '4"Gold Lakshmi Crackers ', actualRate: '₹ 220 ', price: 44 },
    { code: 6, productName: '2 Sound Crackers ', actualRate: '₹ 215', price: 43 },
    { code: 7, productName: '3 Sound Crackers  ', actualRate: '₹ 300', price: 60 },
    { code: 8, productName: 'Lion Gun ', actualRate: '₹ 475', price: 95 },
];

export const chakkar= [
    { code: 9, productName: 'Chakkaram Big ', actualRate: '₹ 300 ', price: 60 },
    { code: 10, productName: 'Chakkaram Special ', actualRate: '₹ 590', price: 118 },
    { code: 11, productName: 'Chakkaram Deluxe ', actualRate: '₹ 1000 ', price: 200 },
    { code: 12, productName: ' Hot Wheel  ', actualRate: '₹ 450', price: 90 },
    { code: 13, productName: 'Spin Master Mini  ', actualRate: '₹ 540', price: 108 },
   
];

export const flowerPots= [
    { code: 14, productName: 'Flower Pots Bomb', actualRate: '₹ 500', price: 100 },
    { code: 15, productName: 'Flower Pots Big ', actualRate: '₹ 600', price: 120 },
    { code: 16, productName: 'Flower Pots Special', actualRate: '₹ 875', price: 175 },
    { code: 17, productName: 'Flower Pots Giant',  actualRate: '₹ 1315 ', price: 263 },
    { code: 18, productName: 'Flower Pots Super (5 pcs) ',  actualRate: '₹ 1570 ', price: 314 },
    { code: 19, productName: 'Colour Koti Special ',  actualRate: '₹ 1115  ', price: 223 }, 
    { code: 20, productName: 'Colour Koti ',  actualRate: '₹ 1970 ', price: 394 },
    { code: 21, productName: 'Colour Koti Deluxe ',  actualRate: '₹ 2920 ', price: 584 }, 
    { code: 22, productName: 'Flower Pots Asoka ',  actualRate: '₹ 860 ', price: 172 },
];

export const bomb = [
    { code: 23, productName: 'Bullet Bomb ', actualRate: '₹ 155 ', price: 31 }, 
    { code: 24, productName: 'Atom Bomb ', actualRate: '₹ 295', price: 59 }, 
    { code: 25, productName: 'Hydro Bomb', actualRate: '₹ 395', price: 79 }, 
    { code: 26, productName: 'King Bomb  ', actualRate: '₹ 490 ', price: 98 }, 
    { code: 27, productName: 'Classic Bomb  ', actualRate: '₹ 735', price: 147 }, 
    { code: 28, productName: 'Paper Bomb 1/4 kg Small  ', actualRate: '₹ 300', price: 60 }, 
    { code: 29, productName: 'Paper Bomb 1/2 kg (Big) ', actualRate: '₹ 600', price: 120 }, 
    { code: 30, productName: 'Paper Bomb 1Kg (Big)  ', actualRate: '₹ 1200', price: 240 }, 
    { code: 31, productName: 'Digital Bomb ', actualRate: '₹ 1300', price: 260 },
];

export const pencil = [
    { code: 32, productName: 'Ultra pencil ', actualRate: '₹ 460', price: 92 },
    { code: 33, productName: 'Selfi Stick (Enjoy) ', actualRate: '₹ 260', price: 52 },
    
];

export const twinklingstar= [
    { code: 34, productName: '1 ½” Twinkling Star',  actualRate: '₹ 175', price: 35 },
    { code: 35, productName: '4” Twinkling Star',  actualRate: '₹ 470', price: 94 },
];


export const rocket = [      
    { code: 36, productName: 'Baby Rocket ',  actualRate: '₹ 245 ', price: 49 },
    { code: 37, productName: 'Rocket Bomb ',  actualRate: '₹ 435 ', price: 87},
    { code: 38, productName: '2 Sound Rocket',  actualRate: '₹ 900 ', price: 180 },
    { code: 39, productName: 'Lunick Rocket',  actualRate: '₹ 850 ', price: 170 },

];

export const fancy = [
    { code: 40, productName: ' Tri Colour ',  actualRate: '₹ 1800', price: 360 },
    { code: 41, productName: 'Peacock Small ',  actualRate: '₹ 900 ', price: 180 },
    { code: 42, productName: 'Peacock Bada',  actualRate: '₹ 3000', price: 600 },
    { code: 43, productName: 'Peacock Feather',  actualRate: '₹ 525', price: 105 },
];
export const shower = [
    { code: 44, productName: 'Cocktail ',  actualRate: '₹ 785', price: 157 },
    { code: 45, productName: 'Chotta Bheam (5pcs) ',  actualRate: '₹ 920', price: 184 },
    { code: 46, productName: 'Ashrafi Shower (5pcs)',  actualRate: '₹ 525', price: 105 },
    
];
export const fancynovelist = [
    { code: 47, productName: 'Helicopter ',  actualRate: '₹ 575', price:115 },
    { code: 48, productName: 'Drone',  actualRate: '₹ 1160', price:232 },
    { code: 49, productName: 'Butterfly ',  actualRate: '₹ 425', price:85 },
    { code: 50, productName: 'Bambaram & Googly Red ',  actualRate: '₹ 680  ', price:136},
    { code: 51, productName: 'Photo Flash ',  actualRate: '₹ 770 ', price:154 },
    { code: 52, productName: 'Photo Flash Red  ',  actualRate: '₹ 825', price:165 },
    { code: 53, productName: 'Photo Flash Silver ',  actualRate: '₹ 300', price:60 },
    { code: 54, productName: 'Siren Mega (2 Pcs) ',  actualRate: '₹ 1330', price:266 },
    { code: 55, productName: 'Smoke ',  actualRate: '₹ 1155 ', price:231 },
   
];
export const fountain = [
    { code: 56, productName: 'Four Square',  actualRate: '₹ 2020', price: 404 },
    { code: 57, productName: '20×20 King Of Hitler',  actualRate: '₹ 750 ', price: 150 },
    { code: 58, productName: 'Money In The Bank ',  actualRate: '₹ 1150', price: 230 },
    { code: 59, productName: '4" Emoji ',  actualRate: '₹ 1075', price: 215 },
    { code: 60, productName: 'Live Show ',  actualRate: '₹ 1075', price: 215 },
    { code: 61, productName: 'Tin  ',  actualRate: '₹ 900', price: 180 },

];

export const fancy2 = [
    { code: 62, productName: 'Chotta 5 in 1', actualRate: '₹ 2175', price: 435 },
    { code: 63, productName: '2" Single', actualRate: '₹ 990', price: 198 },
    { code: 64, productName: '2 1/2" Single', actualRate: '₹ 1750', price: 350 },
    { code: 65, productName: '3" Seven Wonders ', actualRate: '₹ 2350', price: 470 },   
    { code: 66, productName: '3" Single ', actualRate: '₹ 2540', price: 508 },   
    { code: 67, productName: '3 1/2" Single ', actualRate: '₹ 7500', price: 1500 },   
    { code: 68, productName: 'Double Ball Mix', actualRate: '₹ 9075', price: 1815 },
    { code: 69, productName: 'Chotta Single ', actualRate: '₹ 300', price: 60 },
];

export const shot = [
    { code: 70, productName: '7 Shot ',  actualRate: '₹ 600', price: 120 },
    { code: 71, productName: '15 Shot ',  actualRate: '₹ 1625', price: 325 },
    { code: 72, productName: '30 Shot',  actualRate: '₹ 2400', price: 480 },
    { code: 73, productName: '60 Shot',  actualRate: '₹ 4800', price: 960 },
    { code: 74, productName: '120 Shot ',  actualRate: '₹ 9750 ', price: 1950 },
    { code: 75, productName: '240 Shot  ',  actualRate: '₹  19250', price: 3850 },
    
];

export const matches = [
    { code: 76, productName: 'Dora Dora ',  actualRate: '₹ 175', price: 35 },
    { code: 77, productName: 'Matches - Laptop',  actualRate: '₹ 1300', price: 260 },
    { code: 78, productName: 'Matches Single (10 box) ',  actualRate: '₹ 350', price: 70 },
    { code: 79, productName: 'Smoke Stick ',  actualRate: '₹ 125 ', price: 25 },
];

export const sparkles = [
    { code: 80, productName: '12 cm Electric Sparklers ',  actualRate: '₹ 160', price: 32 },
    { code: 81, productName: '12 cm Colour Sparklers', actualRate: '₹ 170', price: 34 },
    { code: 82, productName: '15 cm Electric Sparklers ', actualRate: '₹ 285 ', price: 57 },
    { code: 83, productName: '15 cm Colour Sparklers',  actualRate: '₹ 310', price: 62 },
    { code: 84, productName: '15 cm Green Sparklers',  actualRate: '₹ 330', price: 66 },
    { code: 85, productName: '15 cm Red Sparklers',  actualRate: '₹ 400 ', price: 80 },
    { code: 86, productName: '15 cm Silver Drop Sparklers',  actualRate: '₹ 340 ', price: 68 },
    { code: 87, productName: '30 cm Electric Sparklers ',  actualRate: '₹ 285 ', price: 57 },
    { code: 88, productName: '30 cm Colour Sparklers',  actualRate: '₹ 310 ', price: 62 },
    { code: 89, productName: '30 cm Green Sparklers',  actualRate: '₹ 330  ', price: 66 },
    { code: 90, productName: '30 cm Red Sparklers ',  actualRate: '₹ 405 ', price: 81 },
    { code: 91, productName: '30 cm Sliver Drop Sparklers',  actualRate: '₹ 340 ', price: 68 },
    { code: 92, productName: '50 cm Electric Sparklers',  actualRate: '₹ 850 ', price: 170 },
    { code: 93, productName: '50 cm Colour Sparklers ',  actualRate: '₹ 950 ', price: 190 },
    { code: 94, productName: '50 cm Royal Mix Sparklers',  actualRate: '₹ 1200  ', price: 240 },
];

export const chorsa = [
    { code: 95, productName: 'Red Bijli (100 pcs) ',  actualRate: '₹ 300', price: 60 },
    { code: 96, productName: 'Stripped Bijli (100 pcs) ',  actualRate: '₹ 325 ', price: 65 },
    { code: 97, productName: 'Red Bijli (50 pcs) ',  actualRate: '₹ 210', price: 42 },

];

export const newvarieties = [
    { code: 98, productName: '90 Walts',  actualRate: '₹ 750', price: 150 },
    { code: 99, productName: 'Shin Chan',  actualRate: '₹ 475 ', price: 95 },
    { code: 100, productName: 'Top Gun ',  actualRate: '₹ 900', price: 180 },
    { code: 101, productName: '12 Shot Rider (Long)',  actualRate: '₹ 700', price: 140 },
    { code: 102, productName: '25 Shot Rider',  actualRate: '₹ 1100 ', price: 220 },
    { code: 103, productName: '50 Shot Rider',  actualRate: '₹ 2200 ', price: 440 },
    { code: 104, productName: '1 3/4" Pipe (3 in One) ',  actualRate: '₹ 1350 ', price: 270 },
    { code: 105, productName: 'Snake Tablet (10 box)  ',  actualRate: '₹ 150', price: 30 },
    { code: 106, productName: 'Assorted Cartoon ',  actualRate: '₹ 350', price: 70 },
    { code: 107, productName: 'Sky Shot Green crackling(10 pcs)   ',  actualRate: '₹ 1000', price: 200 },
    { code: 108, productName: 'Water Falls Pencil  ',  actualRate: '₹ 1250', price: 250 },


]

















