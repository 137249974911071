import React from 'react';
import '../styles/Icon.css';

function Icon() {
    return (
        <>
            <div className='float'>
                <a href='https://wa.link/idar0n'><i class="fa-brands fa-whatsapp"></i></a>

            </div>
            <div className='phone'>  <a href='tel:(+91 ) 9843529357'><i class="fa-solid fa-phone"></i></a></div>
        </>
    )
}

export default Icon