import React, { useEffect, useState } from 'react'

const CheckoutTableComponents = ({ item, getQuantity, totalAmount }) => {

    const [totAmount, setTotAmount] = useState(0)
    const [oldQuantoty, setOldQuantity] = useState(0)
   
    useEffect(() => {
        const foundItem = totalAmount.find(cd => cd.code === item.code);

        if (foundItem) {
            setTotAmount(foundItem.price * foundItem.quantity)
            setOldQuantity(foundItem.quantity)
        }else{
            setTotAmount(0)
            setOldQuantity(0)
        }
    }, [totalAmount , oldQuantoty])

    return (
        <tr >
            <td className='fw-normal'>{item.code}</td>
            <td>{item.productName}</td>
            <td className=' text-success fw-bolder'><span className=' text-danger'><s>{item.actualRate}</s></span> <span className='ps-2'> {`₹${item.price}`}</span></td>
            <td><input type='number' value={oldQuantoty > 0 && oldQuantoty} className='form-control qtyInput' onChange={(e) => getQuantity(item, e.target.value)} /></td>
            <td className='fw-bold'>{totAmount > 0 && totAmount}</td>
        </tr>
    )
}

export default CheckoutTableComponents
