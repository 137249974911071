import React from 'react';
import '../styles/payment.css';
import gpay from '../assets/gpay.png'

function Payment() {
    return (
        <div>
            <div className='payment bg bg-fixed bg-overlay-black-70'>

                <div className='payment-inner mt-5'>
                    <div className='payment-title mt-5'>
                        <h3>Payment Options</h3>
                    </div>
                    <div className='paymentlist mt-4'>
                        <ul>
                            <li><i class="fa-solid fa-house"></i><span>Home</span></li>
                            <li><i class="fa-solid fa-angles-right"></i></li>
                            <li>Pages</li>
                            <li><i class="fa-solid fa-angles-left"></i></li>
                            <li>Payment Options</li>
                        </ul>
                    </div>
                </div>
            </div>
            <section className='mt-5'>
                <div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12 col-sm-12 col-md-12'>
                                <h3><u>BANK DETAILS - ACCOUNT - 1</u></h3>
                                <div className='table table-responsive'>
                                    <table className='table table-bordered'>

                                        <tbody>
                                            <tr>
                                                <td>Bank</td>
                                                <td>Karur Vysya Bank</td>
                                            </tr>
                                            <tr>
                                                <td>Branch</td>
                                                <td>S.N.PURAM</td>
                                            </tr>
                                            <tr>
                                                <td>IFSC</td>
                                                <td>KVBL0001810, S.N.PURAM</td>
                                            </tr>
                                            <tr>
                                                <td>Account Name</td>
                                                <td>KANI AGENCIES</td>
                                            </tr>
                                            <tr>
                                                <td>Account Number</td>
                                                <td>1810135000005924</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-sm-12 col-md-12'>
                                <h3><u>BANK DETAILS - ACCOUNT - 2</u></h3>
                                <div className='table table-responsive'>
                                    <table className='table table-bordered'>

                                        <tbody>
                                            <tr>
                                                <td> Bank</td>
                                                <td>Axis Bank</td>
                                            </tr>
                                            <tr>
                                                <td>Branch</td>
                                                <td>SIVAKASI</td>
                                            </tr>
                                            <tr>
                                                <td>IFSC</td>
                                                <td>UTIB0000089 , SIVAKASI</td>
                                            </tr>
                                            <tr>
                                                <td>Account Name</td>
                                                <td>T. Bala karthik</td>
                                            </tr>
                                            <tr>
                                                <td>Account Number</td>
                                                <td>916020030596379</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-sm-12 col-md-12'>
                                <h3><u>BANK DETAILS - ACCOUNT - 3</u></h3>
                                <div className='table table-responsive'>
                                    <table className='table table-bordered'>

                                        <tbody>
                                            <tr>
                                                <td> Bank</td>
                                                <td>Karur Vysya Bank</td>
                                            </tr>
                                            <tr>
                                                <td>Branch</td>
                                                <td>S.N.PURAM</td>
                                            </tr>
                                            <tr>
                                                <td>IFSC</td>
                                                <td>KVBL0001810 , S.N.PURAM</td>
                                            </tr>
                                            <tr>
                                                <td>Account Name</td>
                                                <td>B.Gayathri</td>
                                            </tr>
                                            <tr>
                                                <td>Account Number</td>
                                                <td>1810155000018621</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* <div className='gpay'>
                            <div className='pay-title'>
                                <h3><u>Google pay</u></h3>
                            </div>
                            <div className='pay-image'>
                                <img src={gpay} height={1000} />
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Payment